<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  created() {
    this.logout();
  },
  methods: {
    ...mapActions('User', ['logout']),
  },
};
</script>
